import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError, map, tap, mergeMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { Router } from '@angular/router';
import { LoaderService } from './loader.service';
import { ToastrService } from 'ngx-toastr';
import { UserService } from './user.service';
import { User } from '../model/user.interface';
import { Token } from '../model/token.interface';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {

  isLoggedIn = false;
  private _email!: string;
  private _userPass!: string;
  set userCredentials(credentials: {email: string, password: string}) {
    this._email = credentials.email;
    this._userPass = credentials.password;
  }
  get email(): string {
    return this._email;
  }

  get userPassword(): string {
    return this._userPass;
  }

  constructor(
    private http: HttpClient,
    private router: Router,
    private loaderService: LoaderService,
    private toastr: ToastrService,
    private userService: UserService,
  ) {
    if (this.getToken()) {
      const user = this.userService.user;
      if (user && (user.roles && (user.roles[0] === 'ROLE_ADMIN' || user.roles[0] === 'ROLE_ADMIN_SALES'))) {
        this.isLoggedIn = true;
      } else {
        this.isLoggedIn = false;

        this.logout()
          .subscribe(() => {
            this.loaderService.hide();
            this.router.navigate(['login']);
          });
      }
    }
  }

  login(formValue: {email: string, password: string}): Observable<HttpResponse<Token>> {
      const {email, password} = formValue;
      return this.http.post<Token>(environment.auth, { username: email, password }, { observe: 'response' })
        .pipe(
          tap(({status, body}: HttpResponse<Token>) => {
            if (status === 200 && body) {
              this.storeTokens(body.token, body.refreshToken);
          } else {
            this.userCredentials = {email, password};
          } })
      );
  }

  checkUserType(): Observable<void> {
    return this.userService.getUser()
      .pipe(
        map((user: User) => {
          if (user.roles && (user.roles[0] === 'ROLE_ADMIN' || user.roles[0] === 'ROLE_ADMIN_SALES')) {
            this.loaderService.hide();
            this.isLoggedIn = true;
            this.router.navigate(['company/list']);
          } else {
            this.loaderService.hide();
            this.isLoggedIn = false;
            this.removeTokens();
            this.toastr.error('Access denied');
          }
        }),
    );
  }

  logout(): Observable<boolean> {
    return this.http.delete(environment.logout)
      .pipe(
        map(() => {
          this.isLoggedIn = false;
          this.removeTokens();
          return true;
        }),
        catchError((err: HttpErrorResponse) => {
          this.removeTokens();
          return of(false);
        })
      );
  }

  getToken(): string  | null {
    return localStorage.getItem('token');
  }

  getRefreshToken(): string | null {
    return localStorage.getItem('refreshToken');
  }

  refreshTokens(): Observable<Token> {
    return this.http.post<Token>(environment.refresh, { refreshToken: this.getRefreshToken() })
      .pipe(
        tap((response: Token) => {
          this.storeTokens(response.token, response.refreshToken);
        })
      );
  }

  storeTokens(token: string, refreshToken: string): void {
    localStorage.setItem('token', token);
    localStorage.setItem('refreshToken', refreshToken);
  }

  onRefreshTokenExpired(): void {
    this.removeTokens();
    this.isLoggedIn = false;
    this.loaderService.hide();
    this.toastr.error('Your session has expired! Please login to continue.', 'Error');
    this.router.navigate(['login']);
  }

  removeTokens(): void {
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
  }

  auth2fa(code: string): Observable<boolean | void> {
    return this.http.post<Token>( environment.auth2fa_totp, {
      username: this.email,
      password: this.userPassword,
      code2fa: +code
    }).pipe(
        tap(({ token, refreshToken }) =>
          this.storeTokens(token, refreshToken)
        ),
        mergeMap(() => this.checkUserType()),
        tap(() => this.userCredentials = { email: '', password: '' })
    );
  }
}
